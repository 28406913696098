.main_wrapper_menu {
    width: calc(100% - 145px) !important;
}

#wrapper_top_menu {
    width: calc(100% - 135px);

    .main_menu {
        display: flex;
        justify-content: space-between;
        margin: 0 auto;
        width: 100%;

        .menu {
            width: 100%;

            .superCategories {
                display: flex;
                width: fit-content;
                padding: 1.5rem 2.8% 0 30px;
                font-size: 15px;
                line-height: 1rem;
                font-family: $primaryFont;
                letter-spacing: 0.7px;
                text-transform: uppercase;

                .menu_itm_hover {
                    .menu_supercateg {
                        display: inline-flex;
                        flex-direction: column;
                        justify-content: center;
                        height: 16px;
                        cursor: pointer;
                        padding: 0.5rem 0 0;
                        font-size: 14px;

                        &::after {
                            content: attr(data-text);
                            height: 0;
                            visibility: hidden;
                            overflow: hidden;
                            user-select: none;
                            pointer-events: none;
                            font-weight: 700;
                            letter-spacing: .7px;

                            @media speech {
                                display: none;
                            }
                        }
                    }

                    &.show {
                        font-weight: 700;
                        letter-spacing: 0.04rem
                    }
                }

                .supercateg_item:has(+ .supercateg_item) {
                    display: flex;

                    &::after {
                        display: block;
                        position: relative;
                        top: 50%;
                        transform: translateY(-50%);
                        margin: 3px 0 0 10px;
                        background-color: $black;
                        width: 0.5px;
                        height: 14px;
                        content: '';
                    }
                }
                .supercateg_item + .supercateg_item {
                    .menu_supercateg {
                        padding-left: 10px;
                    }
                }
            }

            .categories {
                padding: 0 0 0 30px;
                width: fit-content;
                -webkit-text-stroke-width: 0px;
                font-size: clamp(12px, fs-vw(14,1920), 14px);
                line-height: clamp(15px, fs-vw(17,1920), 17px);
                letter-spacing: 0.25px;

                @media screen and (max-width: 1024px) {
                    box-sizing: border-box;
                    width: 100%;
                }

                .swiper-container:not(.swiper-initialized) {
                    .swiper-slide {
                        margin-right: 30px;
                    }
                    .swiper-button-next,
                    .swiper-button-prev {
                        opacity: 0;
                    }
                }

                .swiper-button-next.swiper-button-disabled,
                .swiper-button-prev.swiper-button-disabled {
                    opacity: 0;
                }

                .swiper-container {
                    display: none;
                    justify-content: flex-start;
                    align-items: center;
                    opacity: 0;
                    z-index: 1;
                    transition: opacity ease-in 0.35s;
                    margin: 0;
                    width: fit-content;
                    height: 100%;

                    &.show {
                        display: flex;
                        opacity: 1;
                    }

                    .ss_menu_categ_wrapper {
                        &.current,
                        &.show {
                            -webkit-text-stroke-width: 0.75px;
                        }
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            display: inline-block;
                            width: auto;
                            padding-top: 0.5rem;
                            padding-bottom: 1rem;

                            .menu_categ,
                            .menu_categ span {
                                display: block;
                                height: 100%;
                                font-size: 14px;
                            }

                            .menu_categ span {
                                padding: 0 30px 29px 0;
                            }
                        }
                    }
                    
                }

                &:hover {
                    .menu_button {
                        opacity: 1;
                    }
                }

                a.menu_categ,
                a.menu_sscateg,
                a.menu_object {

                    color: var(--textcolor, $primaryColor);

                    &:hover {
                        -webkit-text-stroke-width: 0.75px;
                        color: var(--hovercolor, var(--textcolor, $primaryColor));
                    }
                }

                .menu_button {
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 0px;
                    bottom: 16px;
                    left: 0;
                    transition: opacity ease-in 0.25s;

                    .swiper-button-next,
                    .swiper-button-prev {
                        position: absolute;
                        top: 50%;
                        z-index: 15;
                        cursor: pointer;
                        background-image: none;
                        transform: translateY(-50%);
                        margin: 0;
                        display: block;
                        margin: auto;
                        width: 1rem;
                        height: 41px;
                        color: $primaryColor;
                        background-color: $white;
                        transition: opacity 0.25s ease;

                        &:after {
                            content: '';
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(29,8,8);
                            display: block;
                            width: 1rem;
                            height: 1rem;
                        }
                    }
                    .swiper-button-next {
                        right: 0;
                        padding-top: 5px;

                        &:after {
                            transform: rotate(180deg);
                        }
                        
                    }
                    
                    .swiper-button-prev {
                        left: 0;
                        padding-top: 4px;
                    }
                }
            }

            #menu_fille--4-8-ans- {
                display: flex;
                justify-content: center;
            }

            .SsCategories {
                display: flex;
                position: absolute;
                top: 100%;
                left: 0;
                z-index: -1;
                transition: height 0.12s ease-in-out, z-index 0.3s ease-in-out;
                background-color: $white;
                width: 100%;
                height: 0;
                max-height: 350px;
                overflow: hidden;

                &:has(.show) {
                    z-index: 1;
                }

                &.show {
                    transition: height 0.2s ease-in-out, z-index 0.3s;
                    border-top: 1px solid $beige-clair;

                    @media screen and (max-width: 1024px) {
                        height: auto !important;
                        max-height: fit-content;
                    }
                }

                .wrap_push_sscat {
                    display: none;
                    width: 100%;

                    &.show {
                        display: block;

                        .menu_img {
                            opacity: 1;
                        }
                    }

                    .imgWrapper {
                        position: relative;
                    }

                    .wrap_sscat_links {
                        display: flex;
                        justify-content: space-between;
                        height: 100%;
                        padding: 0 30px 0 195px;   
                    }
                    
                    @media screen and (max-width: 1024px) {
                        .wrap_sscat_links {
                            padding: 0 30px;
                        }
                    }

                    @media screen and (max-width: 1024px) {
                        .wrap_sscat_links {
                            padding: 0 25px;
                        }
                    }

                    .menu_cat {
                        display: flex;
                        justify-content: space-between;
                        font-size: clamp(12px, fs-vw(13,1920), 13px);
                        line-height: clamp(24px, fs-vw(25, 1920), 25px);
                        gap: clamp(30px, fs-vw(110,1920), 110px);
                        padding: 1rem 0;

                        .menu_cat_categories {
                            display: flex;
                            flex-wrap: wrap;
                            height: 100%;
                            min-height: 250px;
                            gap: clamp(30px, fs-vw(110,1920), 110px);

                            @media screen and (max-width: 1660px) {
                                flex-wrap: nowrap;
                                gap: clamp(30px, fs-vw(30,1920), 110px);
                            }

                            ul {
                                display: flex;
                                flex-direction: column;
                                flex-wrap: wrap;
                                height: 100%;
                                max-height: 250px;

                                @media screen and (max-width: 1660px) {
                                    flex-wrap: nowrap;
                                    max-height: none;
                                }
                            }

                            .voir_tout {
                                color: $taupe;
                            }
                        }

                        li {
                            padding-right: 15px;
                            a {
                                transition: all ease-in-out 0.25s;
                                -webkit-text-stroke-width: 0px;

                                &:hover {
                                    -webkit-text-stroke-width: 1px;
                                }
                            }
                        }
                    }

                    .menu_img {
                        display: flex;
                        gap: 5px;
                        opacity: 0;
                        padding-top: 5px;
                        animation-duration: 1s;
                        animation-iteration-count: 1;
                        animation-name: fade;
                        overflow: hidden;
                        
                        @media screen and (max-width: 1660px) {
                            align-content: flex-start;
                        }
                        
                        img {
                            width: 100%;
                        }

                        .item {
                            position: relative;

                            @media screen and (max-width: 1024px) {
                                max-width: 240px;
                                flex: 1 1 calc(50% - 5px);
                            }

                            &.push {
                                .imgWrapper {
                                    a {
                                        .ill_img,
                                        .no_lazy {
                                            display: block;
                                            width: 100%;
                                            max-width: 240px;
                                        }
                                    }
                                }

                                @media screen and (max-width: 1660px) {
                                    .imgWrapper {
                                        a {
                                            .ill_img,
                                            .no_lazy {
                                                flex: 1;
                                            }
                                        }
                                    }
                                }
                            }

                            .infos {
                                display: grid;
                                position: absolute;
                                top: 15px;
                                right: 0;
                                bottom: 25px;
                                left: 0;
                                flex-wrap: wrap;
                                align-content: center;
                                justify-content: center;
                                margin: auto;
                                color: $white;
                                text-align: center;

                                .alt_bandeau {
                                    font-style: italic;
                                    font-size: 25px;
                                    line-height: 59px;
                                    font-family: $secondaryFont;
                                }

                                .bandeau_link {
                                    font-size: 12px;
                                    line-height: 14px;
                                    letter-spacing: 0.7px;
                                    text-decoration: underline;
                                    text-underline-offset: 3px;
                                    text-transform: uppercase;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 1921px) {
    #site_head_wrap .headerContainer.sticky .main_wrapper_menu #wrapper_top_menu .main_menu .menu .SsCategories .wrap_push_sscat .wrap_sscat_links,
    #wrapper_top_menu .main_menu .menu .SsCategories .wrap_push_sscat .wrap_sscat_links {
        padding-left: calc(180px + ((100vw - 1920px) / 2));
        padding-right: calc(350px + ((100vw - 1920px) / 2));
    }
}

.sticky #wrapper_top_menu .main_menu .menu {
    .categories .swiper-container .swiper-wrapper .swiper-slide .menu_categ span {
        padding-bottom: 1rem;
    }
}

@keyframes fade {
    0%   {opacity: 0;}
    50%  {opacity: 0.5;}
    100% {opacity: 1;}
}

@keyframes hidedownsscat {
    0%   {height: 100%; min-height: 317px;}
    100% {height: 0; min-height: 0px;}
}

@keyframes showdownsscat {
    0%   {height: 0; min-height: 0px;}
    100% {height: 100%; min-height: 317px;}
}
