/* Fiche Produit */
body.product_page {
    .page_produit {
        padding: 0 clamp(1rem, fs-vw(30, 1920), 30px);
        max-width: 100%;
        .top_page_produit {
            display: flex;
            justify-content: flex-start;
            gap: clamp(1rem, fs-vw(30, 1920), 30px);
            margin-bottom: 3rem;
            width: 100%;

            .product_main_wrapper {
                position: relative;
                width: fit-content;
                min-width: 45%;

                .itm_views {
                    display: flex;
                    position: sticky;
                    top: 100px;
                    gap: 0.5rem;
                    width: 100%;

                    .thumbsFpContainer {
                        position: relative;
                        width: 118px;
                        max-width: 118px;

                        &:has(.swiper-initialized) .nextThumbFp,
                        &:has(.swiper-initialized) .prevThumbFp {
                            opacity: 1;
                        }

                        .nextThumbFp,
                        .prevThumbFp {
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            position: absolute;
                            top: 10px;
                            right: 0;
                            left: 0;
                            transform: rotate(90deg);
                            opacity: 0;
                            z-index: 5;
                            cursor: pointer;
                            margin: auto;
                            margin: auto;
                            width: 1rem;
                            height: 1rem;

                            &.swiper-button-disabled {
                                opacity: 0;
                            }
                        }

                        .nextThumbFp {
                            top: inherit;
                            bottom: 10px;
                            transform: rotate(-90deg);
                        }

                        .thumbSlideFp {
                            position: relative;
                        }

                        .swiper-wrapper {
                            .swiper-slide {
                                position: relative;
                                cursor: pointer;
                                margin-bottom: 5px;
                                height: 0;
                                padding-top: calc((145 / 116) * 100%);
                                width: 116px;

                                overflow: hidden;

                                img {
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                    cursor: pointer;
                                    width: auto;
                                    height: 100%;
                                }

                                &.swiper-slide-thumb-active {
                                    border: 1px solid $black;
                                }
                            }
                        }
                    }

                    .mainSlideFp {
                        position: relative;
                        width: calc(100% - 120px);
                        max-width: 716px;
                        height: fit-content;
                        &::after {
                            content: "";
                            position: absolute;
                            display: block;
                            width: 1px;
                            height: 100%;
                            top: 0;
                            right: 0;
                            background-color: white;
                            z-index: 1;
                        }

                        .swiper-wrapper {
                            .swiper-slide {
                                img {
                                    display: block;
                                    cursor: pointer;
                                    width: 100%;
                                    max-width: 716px;
                                    height: auto;
                                    max-height: 895px;
                                }

                                .zoomImg_click {
                                    position: absolute;
                                    top: 0;
                                    left: 0;
                                    cursor: pointer;
                                    width: 100%;
                                    height: 100%;
                                }

                                &.slide_video,
                                &.content_video {
                                    display: block;
                                    position: relative;
                                    background-color: $primaryColor;
                                    padding-bottom: calc((895 / 716) * 100%);
                                    width: 100%;
                                    height: 0;

                                    iframe {
                                        display: block;
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        z-index: 0;
                                        background: $black;
                                        width: 100%;
                                        height: 100%;
                                    }
                                }
                            }
                        }

                        .eclat_rond,
                        .eclatProd {
                            display: block !important;
                            position: absolute;
                            top: 8px;
                            right: 15px;
                            z-index: 5;

                            img {
                                width: 100%;
                                height: auto;
                                max-height: 19px;
                            }
                        }

                        .eclat_rect {
                            position: absolute;
                            top: 8px;
                            left: 11px;
                            z-index: 5;

                            img {
                                width: 100%;
                                height: auto;
                                max-height: 19px;
                            }
                        }

                        .swiper-button-next,
                        .swiper-button-prev {
                            background-image: url(../svg/spritesheet.svg);
                            @include bgIconCoord(29,8,8);
                            position: absolute;
                            top: 0;
                            right: auto;
                            bottom: 0;
                            left: 10px;
                            opacity: 0;
                            z-index: 5;
                            cursor: pointer;
                            margin: auto;
                            width: 1rem;
                            height: 1rem;

                            &:after {
                                display: none;
                            }
                        }

                        .swiper-button-next {
                            right: 10px;
                            left: auto;
                            transform: rotate(180deg);
                        }

                        &.swiper-initialized {
                            .swiper-button-next,
                            .swiper-button-prev {
                                opacity: 1;

                                &.swiper-button-disabled {
                                    opacity: 0.35;
                                }
                            }
                        }
                    }
                }
            }

            .product_info_wrapper {
                position: relative;
                flex-grow: 1;
                width: 100%;
                overflow: hidden;

                .pos_sticky {
                    position: sticky;
                    top: 0;
                    padding-bottom: 100px;
                    max-width: 100%;

                    &:has(.sticky_bar_fp) {
                        padding-top: 300px;
                    }
                }

                .sticky_bar_fp #product_addcart .disabledBtnTooltip .disabledBtnInfos {
                    margin: 0;
                }

                .restock_date,
                #ligne_qte,
                .stock_delivery:not(.preorder) .delivery_date,
                .inStock {
                    display: none;
                }

                .product_info {
                    .wishlist_brands_review_wrapper {
                        display: flex;
                        justify-content: space-between;
                        margin-bottom: 10px;
                        width: 100%;

                        .container_marques {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;

                            img {
                                display: block;
                                width: auto;
                                height: 22px;
                            }

                            a {
                                padding: 5px 0;
                            }
                        }

                        .btnWishlist  {
                            background-image: url(../svg/spritesheet.svg);
                            width: 22px;
                            height: 22px;
                            @include bgIconCoord(20, 8, 8);
                            display: block;
                            margin: 0;

                            span {
                                font-size: 0;
                            }

                            &.existToWishlistButton {
                                @include bgIconCoord(21, 8, 8);
                            }
                        }
                    }

                    .wrapper_name_price {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                        margin-bottom: 1rem;

                        #itm_name {
                            font-style: normal;
                            font-weight: 600;
                            font-size: 1.25rem;
                            line-height: 25px;
                            font-family: $primaryFont;
                        }

                        #itm_subtitle {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 1rem;
                            line-height: 25px;
                            font-family: $primaryFont;
                        }

                        .price_container {
                            display: flex;
                            flex-direction: row-reverse;
                            flex-wrap: wrap;

                            div.price {
                                position: relative;
                                margin-left: 12px;
                                height: 18px;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 17px;
                                line-height: 1;
                                font-family: $primaryFont;
                                text-align: right;

                                .currency {
                                    font-size: 1rem;
                                }

                                & + div.price {
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 15px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    text-align: right;

                                    &:before {
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                        background-color: $grey;
                                        width: 100%;
                                        height: 1px;
                                        content: '';
                                    }

                                    .currency {
                                        font-size: 14px;
                                    }
                                }
                            }

                            .ecoPart {
                                margin-top: 5px;
                                width: 100%;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 11px;
                                line-height: normal;
                                font-family: $primaryFont;
                                text-align: right;
                            }
                        }
                    }

                    #bloc_add_alert {
                        &.inactiv {
                            display: none;
                        }

                        .bloc_add_alert_confirmation {
                            display: inline-block;
                            position: relative;
                            box-sizing: border-box;
                            margin: 25px 0;
                            padding-left: 15px;
                            width: auto;
                            max-width: 445px;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            text-align: center;

                            br {
                                display: none;
                            }

                            &:before {
                                background-image: url(../svg/spritesheet.svg);
                                width: 1rem;
                                height: 1rem;
                                content: '';
                                @include bgIconCoord(44,8,8);
                                display: block;
                                position: absolute;
                                top: 0;
                                left: 0;
                            }
                        }
                    }
                }

                .stock_delivery {
                    &.preorder {
                        margin-bottom: 2rem;
                        font-size: 1rem;
                        line-height: 20px;
                        font-family: $primaryFont;

                        span {
                            font-weight: 600;
                        }
                    }
                }

                 /* Couleurs */
                .productColorFieldset {
                    .choices_list {
                        margin: 10px 0 0 0;
                        padding-left: 1px;
                        max-width: calc(100% - 25px);
                        height: 42px;

                        .swiper-slide {
                            display: inline-flex;
                            margin-right: 30px;
                            width: auto;
                        }

                        input[type="radio"] {
                            position: absolute;
                            opacity: 0;
                            z-index: 2;
                            cursor: pointer;
                            width: 100%;
                            height: 18px;

                            + label {
                                display: block;
                                position: relative;
                                z-index: 2;
                                cursor: pointer;
                                box-shadow: inset 0 0 0 3px $white;
                                border-radius: 100%;
                                background-position: center;
                                background-size: contain !important;
                                background-repeat: no-repeat;
                                padding: 1px;
                                width: 25px;
                                height: 25px;
                                color: $grey;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;

                                &:before {
                                    display: block;
                                    position: absolute;
                                    top: 0;
                                    bottom: 0;
                                    left: 0px;
                                    cursor: pointer;
                                    margin: auto;
                                    border: 1px solid $lightgrey;
                                    border-radius: 100%;
                                    background: transparent;
                                    width: 25px;
                                    height: 25px;
                                    content: '';
                                }

                                & + .color_txt {
                                    display: block;
                                    display: inline-block;
                                    transition: all ease-in-out 0.5s;
                                    margin-left: 6px;
                                    padding-top: 4px;
                                    -webkit-text-stroke-width: 0px;
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 500;
                                    font-size: 13px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    text-transform: capitalize;
                                }
                            }

                            &:hover,
                            &:checked {
                                & + label {
                                    color: $black;
                                    font-weight: 600;

                                    &:before {
                                        border: 1px solid $black;
                                    }

                                    &  + .color_txt {
                                        color: $black;
                                    }
                                }
                            }

                            &:checked:hover {
                                cursor: default;
                            }
                        }

                        &:not(.swiper-container-initialized) {
                            + .prevColorFp {
                                display: none;

                                +.nextColorFp {
                                    display: none;
                                }
                            }
                        }
                    }

                    .prevColorFp,
                    .nextColorFp {
                        display: block;
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        z-index: 10;
                        cursor: pointer;
                        border-top: 8px solid transparent;
                        border-bottom: 8px solid transparent;
                        width: 0;
                        height: 0;

                        &.swiper-button-disabled {
                            display: none;
                        }
                    }

                    .prevColorFp {
                        left: -20px;
                        border-right: 12px solid $primaryColor;
                    }

                    .nextColorFp {
                        right: 0;
                        border-left: 12px solid $primaryColor;
                    }
                }

                /* Tailles */
                .productSizeFieldset {
                    .choices_list {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: flex-start;
                        align-items: flex-end;
                        padding-bottom: 1rem;

                        .form_itm {
                            position: relative;
                            margin-right: 15px;

                            input[type="radio"] {
                                position: absolute;
                                opacity: 0;
                                z-index: -1;
                                cursor: pointer;
                                margin: 0;
                                width: 100%;
                                height: 100%;

                                & + label {
                                    display: inline-block;
                                    position: relative;
                                    cursor: pointer;
                                    cursor: pointer;
                                    border-bottom: 1px solid $white;
                                    padding-bottom: 3px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                }

                                &:hover,
                                &:checked {
                                    & + label {
                                        border-bottom: 1px solid $black;
                                        font-weight: 600;
                                    }
                                }

                                &:checked:hover {
                                    cursor: default;
                                }

                                &.disabled {
                                    & + label {
                                        color: $grey;
                                    }

                                    &:hover,
                                    &:checked {
                                        & + label {
                                            border-bottom: 1px solid $grey;
                                            font-weight: 600;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                #size_is_not_available {
                    display: block;
                    margin: 0.5rem 0 1rem;
                    color: $red;
                    font-size: 15px;
                }

                #product_addcart,
                [id^="product_addcart_"] {
                    position: relative;

                    #bloc_add_basket {
                        position: relative;
                        width: 100%;
                        max-width: 415px;

                        #btn_add_cart {
                            transition: all 0.35s ease-in-out;
                            cursor: pointer;
                            margin: 10px 0 24px;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-color: $black;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;
                            color: $white;
                            color: $white;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;

                            &:hover {
                                background-color: $white;
                                color: $black;
                            }
                        }

                        .loader {
                            position: absolute;
                            top: 10px;
                            left: 0;
                            opacity: 0;
                            z-index: -1;
                            border-radius: 50px;
                            background: $black url(../svg/three_dots.svg) no-repeat center;
                            background-size: 15% auto;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;

                            span {
                                font-size: 0;
                            }

                            &.loading {
                                opacity: 1;
                                z-index: 1;
                            }
                        }

                        &.inactiv {
                            display: none;

                            #btn_add_cart {
                                opacity: 0.5;
                                cursor: default;
                            }
                        }
                    }

                    #bloc_add_alert {
                        #btn_alert_stock {
                            cursor: pointer;
                            margin: 10px 0 24px;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-color: $white;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;
                        }

                        #keep_alive_stock {
                            .w-alertestock-form {
                                position: relative;
                                margin: 25px 0 24px;
                                width: 100%;
                                max-width: 415px;
                                min-height: 48px;

                                .w-form-line {
                                    margin-bottom: 0;

                                    &.w-submit {
                                        position: absolute;
                                        top: 10px;
                                        right: 0;
                                        width: 13px;

                                        .w-submit-button {
                                            border: none !important;
                                            border-radius: 0 !important;
                                            background-image: url(../svg/spritesheet.svg);
                                            background-color: inherit !important;
                                            width: 13px;
                                            height: 13px !important;
                                            @include bgIconCoord(29,8,8);
                                            transform: rotate(180deg);
                                            opacity: 0.5;

                                            span {
                                                font-size: 0 !important;
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .bloc_add_alert_erreur {
                            color: $red;
                            font-size: 14px;
                            font-family: $primaryFont;

                            strong {
                                display: block;
                                font-weight: 500;
                            }
                        }

                        &.inactiv {
                            #btn_alert_stock {
                                opacity: 0.75;
                                cursor: default;
                            }
                        }
                    }

                    .boutique_n_list_wrapper {
                        display: flex;
                        justify-content: flex-start;
                        align-items: center;
                        margin: 0 0 30px;

                        .form_submit.pdvWrapper {
                            position: relative;

                            .btnFindRetailer {
                                position: relative;
                                margin-right: 36px;
                                margin-left: 23px;
                                border:none;
                                border-bottom: 1px solid $black;
                                border-radius: 0;
                                background-color: $white;
                                padding: 0;
                                height: auto;
                                color: $black;

                                span {
                                    display: block;
                                    left: 0.6px;
                                    max-width: 200px;
                                    overflow: hidden;
                                    font-size: 12px;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                &:before {
                                    background-image: url(../svg/spritesheet.svg);
                                    content: '';
                                    @include bgIconCoord(5,8,8);
                                    display: inline-block;
                                    position: absolute;
                                    top: -2px;
                                    left: -23px;
                                    width: 20px;
                                    height: 20px;
                                }

                                span {
                                    color: $black;
                                }
                            }

                            .choose_a_size_mag {
                                position: absolute;
                                top: 15px;
                                left: 0;
                                opacity: 0;
                                transition: opacity ease-in-out 0.35s;
                                margin: 10px 0;
                                color: $red;
                                font-size: 12px;
                                font-family: $primaryFont;
                            }

                            &.inactiv {
                                .btnFindRetailer {
                                    cursor: default;

                                    &:hover {
                                        & + .choose_a_size_mag {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }

                        #ctaAddToGiftList {
                            position: relative;
                            margin: 0;
                            border:none;
                            border-bottom: 1px solid $black;
                            border-radius: 0;
                            background-color: $white;
                            padding: 0;
                            height: auto;
                            color: $black;

                            &.inactiv {
                                cursor: default;
                            }

                            &.actif {
                                &:before {
                                    @include bgIconCoord(35,8,8);
                                }
                            }

                            span {
                                display: block;
                                left: 0.6px;
                                max-width: 200px;
                                overflow: hidden;
                                font-size: 12px;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            &:before {
                                background-image: url(../svg/spritesheet.svg);
                                content: '';
                                @include bgIconCoord(3,8,8);
                                display: inline-block;
                                position: absolute;
                                top: -2px;
                                left: -23px;
                                width: 20px;
                                height: 20px;
                            }

                            span {
                                color: $black;
                            }
                        }

                        #gl_btn_error {
                            display: none !important;
                        }
                    }

                    .disabledBtnTooltip {
                        display: none;
                        position: relative;
                        top: initial;
                        order: -1;
                        opacity: 0.5;
                        border: none;
                        padding: 0;
                        width: 100%;
                        max-width: 415px;
                        cursor: default;

                        &:before {
                            display: none;
                        }

                        &:has(.cache) {
                            display: none;
                        }

                        &.actif {
                            display: block;
                        }

                        &.blink {
                            animation: blinkBtn 1.5s linear infinite;
                        }

                        .disabledBtnInfos {
                            display: flex;
                            justify-content: center;
                            align-items: center;
                            transition: all .35s ease-in-out;
                            cursor: pointer;
                            margin: 10px 0 24px;
                            border: 1px solid #000;
                            border-radius: 50px;
                            background-color: #000;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;
                            color: #fff;
                            color: #fff;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: .65px;
                            text-transform: uppercase;
                            cursor: inherit;
                        }
                    }
                }

                #product_addcart,
                [id^="product_addcart_"] {
                    display: flex;
                    flex-direction: column;
                    height: 131px;
                    justify-content: flex-end;

                    #bloc_add_alert {
                        order: -1;
                    }

                    &.product_addcart_look {
                        position: relative;
                        margin-bottom: 24px;

                        #addLookToBasket {
                            transition: all 0.35s ease-in-out;
                            cursor: pointer;
                            margin: 0;
                            border: 1px solid $black;
                            border-radius: 50px;
                            background-color: $black;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;
                            color: $white;
                            color: $white;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 13px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;

                            &:hover {
                                background-color: $white;
                                color: $black;
                            }
                        }

                        .loader {
                            position: absolute;
                            top: 0;
                            left: 0;
                            opacity: 0;
                            z-index: -1;
                            border-radius: 50px;
                            background: $black url(../svg/three_dots.svg) no-repeat center;
                            background-size: 15% auto;
                            width: 100%;
                            max-width: 415px;
                            height: 48px;
                            font-size: 0;

                            &.loading {
                                opacity: 1;
                                z-index: 1;
                            }
                        }

                        .error {
                            padding-top: 1rem;
                            color: $red;
                            font-size: 14px;
                            font-family: $primaryFont;
                        }

                        & > #giftListAdd {
                            display: inline-block;
                            padding-left: 23px;
                            width: auto;
                            max-width: 170px;

                            #ctaAddToGiftList {
                                position: relative;
                                margin: 0;
                                margin-top: 24px;
                                border: none;
                                border-bottom: 1px solid $black;
                                border-radius: 0;
                                background-color: $white;
                                padding: 0;
                                height: auto;
                                color: $black;

                                &.inactiv {
                                    cursor: default;
                                }

                                &.actif {
                                    &:before {
                                        @include bgIconCoord(35,8,8);
                                    }
                                }

                                span {
                                    display: block;
                                    left: 0.6px;
                                    max-width: 200px;
                                    overflow: hidden;
                                    font-size: 12px;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;
                                }

                                &:before {
                                    display: inline-block;
                                    position: absolute;
                                    top: -2px;
                                    left: -23px;
                                    width: 20px;
                                    height: 20px;
                                    content: '';
                                    @include bgIconPath('../svg/spritesheet.svg');
                                    @include bgIconCoord(3,8,8);
                                }

                                span {
                                    color: $black;
                                }
                            }
                        }
                    }
                }

                .reassurance_wrapper {
                    display: flex;
                    position: relative;
                    justify-content: center;
                    align-items: center;
                    box-sizing: border-box;
                    margin-bottom: 15px;
                    background: white;
                    width: 100%;
                    height: 20px;
                    overflow: hidden;
                    white-space: nowrap;
                }

                .reassurance_fp {
                    display: flex;
                    position: absolute;
                    align-items: center;
                    animation: marquee2 26s linear infinite;
                    width: 200%;

                    .bloc {
                        float: left;
                        overflow: hidden;
                    }

                    .txt {
                        display: inline-block;
                        margin-right: 28px;
                        width: auto;
                        color: $grey;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: normal;
                        font-family: $primaryFont;
                        white-space: nowrap;
                    }
                }

                #description_produit {
                    border-bottom: 1px solid $lightgrey;

                    .desc_pannel {
                        .toggleCarac {
                            position: relative;
                            cursor: pointer;
                            border-top: 1px solid $lightgrey;
                            color: $black;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 45px;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;

                            .ico {
                                img {
                                    display: inline-block;
                                    position: relative;
                                    top: 8px;
                                    margin-right: 8px;
                                    width: auto;
                                    height: 26px;
                                }
                            }

                            &:after {
                                background-image: url(../svg/spritesheet.svg);
                                content: '';
                                @include bgIconCoord(29,8,8);
                                display: inline-block;
                                position: absolute;
                                top: 12px;
                                right: 0;
                                transform: rotate(-90deg);
                                transition: all 0.35s ease-in-out;
                                width: 1rem;
                                height: 1rem;
                            }

                            & + .content {
                                display: none;
                                transition: max-height 0.5s linear;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;

                                .product_ref {
                                    margin-bottom: 25px;
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 1;
                                    font-family: $primaryFont;

                                    & + br {
                                        display: none;

                                        & + br {
                                            display: none;
                                        }
                                    }
                                }

                                .caract {
                                    display: flex;
                                    justify-content: flex-start;
                                    margin-top: 6px;
                                }

                                .libelle {
                                    display: inline-block;
                                    width: 100%;
                                    max-width: 226px;
                                }

                                .value {
                                    width: 100%;
                                }

                                .download_pdf {
                                    display: flex;
                                    flex-direction: row;
                                    margin-top: 1.4rem;
                                    text-transform: uppercase;
                                    font-family: $primaryFont;
                                    font-size: 12px;
                                    font-style: normal;
                                    font-weight: 500;
                                    line-height: normal;
                                    letter-spacing: 0.6px;
                                    align-items: flex-end;

                                    &::before {
                                        content: '';
                                        display: block;
                                        background-image: url(../svg/book.svg);
                                        width: 21px;
                                        height: 16px;
                                        margin-right: .4rem;
                                    }

                                    span {
                                        margin-right: .35rem;
                                    }

                                    .link_pdf {
                                        position: relative;

                                        &::after {
                                            content: '';
                                            display: block;
                                            width: 100%;
                                            height: 1px;
                                            border-bottom: 1px solid $black;
                                            position: absolute;
                                            bottom: -.1rem;
                                        }
                                    }
                                }
                            }


                            &.actif {
                                border-bottom: 1px solid $white;

                                & + .content {
                                    padding-bottom: 20px;
                                }

                                &:after {
                                    transform: rotate(90deg);
                                }
                            }

                            &.toggleLocation {
                                display: flex;
                                position: relative;
                                align-items: center;

                                &:before {
                                    background-image: url(../svg/spritesheet.svg);
                                    width: 26px;
                                    height: 26px;
                                    content: '';
                                    @include bgIconCoord(19,8,8);
                                    display: block;
                                    margin-right: 0.7rem;
                                }
                            }
                        }

                        #products_look {
                            .content {
                                .associated_product {
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;
                                    margin-bottom: 10px;
                                    border-bottom: 1px solid $lightgrey;
                                    padding-bottom: 10px;

                                    &:last-of-type {
                                        margin-bottom: 0;
                                        border-bottom: none;
                                        padding-bottom: 0;
                                    }

                                    .associated_product_img {
                                        margin-right: 3.558%;
                                        width: 80px;

                                        img {
                                            width: 100%;
                                            height: auto;
                                        }
                                    }

                                    .associated_product_desc {
                                        padding-right: 1rem;
                                        width: 26.33%;

                                        .brand {
                                            margin-bottom: 5px;
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: normal;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.04rem;
                                            text-transform: uppercase;
                                        }

                                        .title,
                                        .subtitle {
                                            color: $black;
                                            font-style: normal;
                                            font-weight: 600;
                                            font-size: 14px;
                                            line-height: 18px;
                                        }

                                        .subtitle {
                                            font-weight: 400;
                                        }

                                        .price_container {
                                            display: flex;
                                            flex-direction: row-reverse;
                                            justify-content: flex-end;
                                            align-items: center;
                                            margin-top: 8px;

                                            .price {
                                                color: $black;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 17px;
                                                line-height: normal;
                                                font-family: $primaryFont;

                                                & + .price {
                                                    margin-right: 5px;
                                                    color: $grey;
                                                    font-size: 15px;
                                                    text-decoration: line-through;

                                                    span {
                                                       color: $grey;
                                                       font-size: 15px;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .associated_product_color {
                                        width: 16%;

                                        .preview_color {
                                            display: flex;
                                            align-items: center;

                                            .picto {
                                                display: block;
                                                margin-right: 8px;
                                                box-shadow: inset 0 0 0 3px $white;
                                                border:1px solid $black;
                                                border-radius: 100%;
                                                width: 20px;
                                                min-width: 20px;
                                                height: 20px;
                                            }

                                            .color_txt {
                                                color: $black;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 13px;
                                                line-height: normal;
                                                font-family: $primaryFont;
                                            }
                                        }
                                    }

                                    .associated_product_sizes {
                                        position: relative;
                                        border-top: 1px solid $lightgrey;
                                        border-bottom: 1px solid $lightgrey;
                                        width: 13.28%;

                                        select {
                                            position: relative;
                                            appearance: none;
                                            cursor: pointer;
                                            border: none;
                                            background-color: transparent;
                                            padding: 3px 0;
                                            width: 100%;
                                            color: $black;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: 48px;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.6px;
                                            text-align: center;
                                            text-transform: uppercase;

                                            &:-moz-focusring {
                                              color: transparent;
                                              text-shadow: 0 0 0 $black;
                                            }

                                            option {
                                                color: $black;
                                                font-style: normal;
                                                font-weight: 500;
                                                font-size: 12px;
                                                font-family: $primaryFont;
                                                text-transform: uppercase;

                                                &.product_unavailable {
                                                    color: $grey;
                                                }
                                            }

                                            &:active,
                                            &:focus,
                                            &:hover {
                                                outline: none;
                                                border: none;
                                                color: $black;
                                            }

                                            &:has(.product_unavailable:checked) {
                                                color: $grey;
                                            }
                                        }
                                    }

                                    .associated_product_action {
                                        display: flex;
                                        flex-direction: column;
                                        align-items: flex-end;
                                        width: 31.43%;

                                        .achat_express_v1 {
                                            cursor: pointer;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: normal;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.6px;
                                            text-decoration: underline;
                                            text-underline-position: under;
                                            text-transform: uppercase;
                                        }
                                    }

                                    &:not(.has_multiple_sizes) {
                                        .associated_product_sizes {
                                            select {
                                                pointer-events: none;
                                                text-align: center;
                                            }
                                        }
                                    }

                                    &.has_multiple_sizes {
                                        .associated_product_sizes {
                                            &:after {
                                                content: '';
                                                @include bgIconPath('../svg/spritesheet.svg');
                                                @include bgIconCoord(29,8,8);
                                                display: inline-block;
                                                position: absolute;
                                                top: 0;
                                                right: 0;
                                                bottom: 0;
                                                transform: rotate(-90deg);
                                                z-index: -1;
                                                transition: all 0.35s ease-in-out;
                                                margin: auto;
                                                background-color: $white;
                                                width: 1rem;
                                                height: 1rem;
                                            }

                                            select {
                                                padding-right: 1rem;    
                                            }
        
                                        }
                                    }

                                    @media only screen and (max-width: 1540px) {
                                        flex-wrap: wrap;

                                        .associated_product_desc {
                                            width: calc(76.442% - 80px);
                                        }

                                        .associated_product_color {
                                            width: 20%;
                                        }

                                        .associated_product_sizes {
                                            margin-top: 10px;
                                            width: 25%;
                                        }

                                        .associated_product_action {
                                            width: 38%;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .autre_pdt_container,
                .assoc_product {
                    .autre_pdt_tab_list {
                        padding: 1rem 0;

                        .assocTitle {
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-align: left;
                            text-transform: uppercase;
                        }
                    }

                    .autres_pdt_holder {
                        width: 100%;
                        #assoc_product_slider_Fp {
                            padding-bottom: 30px;
                            width: 100%;

                            .assoc-swiper-wrapper {
                                .swiper-slide {
                                    display: inline-flex;
                                    flex-shrink: 0;
                                    gap: 1rem;
                                    width: auto;
                                    max-width: 100%;
                                    height: 100%;
                                    overflow: hidden;

                                    #addToWishlistButtonContainer {
                                        display: none;
                                    }

                                    .productVisualMulti {
                                        margin-bottom: 0;
                                        width: 100px;
                                        min-width: 100px;

                                        .wrap_video {
                                            a {
                                                position: absolute;
                                                top: 0px;
                                                left: 0px;
                                                display: inline-block;
                                                width: 100%;
                                                height: 100%;
                                                z-index: 5;
                                            }
                                        }
                                    }

                                    .description_achat_express_wrapper {
                                        display: flex;
                                        flex-direction: column;
                                        justify-content: space-between;
                                        padding-top: 7px;
                                        max-width: 100%;
                                        overflow: hidden;

                                        .description_achat_express_details {
                                            display: flex;
                                            flex-direction: column;
                                            gap: 0.5rem;
                                        }

                                        .container_marques {
                                            font-style: normal;
                                            font-weight: 400;
                                            font-size: 13px;
                                            line-height: 1;
                                            font-family: $primaryFont;
                                            letter-spacing: .65px;
                                            text-transform: uppercase;
                                        }

                                        .wrapper_description {
                                            padding: 7px 0 0;
                                            width: 100%;

                                            .item_title_wrapper,
                                            .item_price {
                                                justify-content: flex-end;
                                                width: 100%;
                                                text-align: left;
                                            }

                                            .item_title_wrapper {
                                                padding-bottom: 0;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }

                                            .item_subtitle {
                                                margin-bottom: 5px;
                                                overflow: hidden;
                                                text-overflow: ellipsis;
                                                white-space: nowrap;
                                            }
                                        }

                                        .achat_express_v1 {
                                            display: block !important;
                                            cursor: pointer;
                                            padding-bottom: 0.2rem;
                                            font-style: normal;
                                            font-weight: 500;
                                            font-size: 12px;
                                            line-height: normal;
                                            font-family: $primaryFont;
                                            letter-spacing: 0.6px;
                                            text-decoration: underline;
                                            text-underline-position: under;
                                            text-transform: uppercase;
                                        }

                                        .item_price {
                                            display: flex;
                                            flex-direction: row-reverse;
                                            justify-content: flex-end;

                                            .pricetag {
                                                .price {
                                                    span {
                                                        color: $black;
                                                        font-size: 15px;
                                                    }
                                                }

                                                & + .pricetag {
                                                    margin-right: 0.5rem;

                                                    .price {
                                                        color: $grey;
                                                        text-decoration: line-through;

                                                        span {
                                                            color: $grey;
                                                            font-size: 14px;
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    .wrap_rolloverproduit,
                                    .eclat_rond,
                                    .eclat_rect {
                                        display: none;
                                    }
                                }
                            }

                            .swiper-scrollbar {
                                top: auto;
                                bottom: 10px;
                                opacity: 0;
                                transition: opacity 0.35s ease-in-out;
                                border-radius: 20px;
                                background-color: $white;
                                height: 14px;

                                .swiper-pagination-progressbar-fill {
                                    border-top: 4px solid $white;
                                    border-bottom: 4px solid $white;
                                    border-radius: 20px;
                                    background-color: $black;
                                }

                                .swiper-scrollbar-drag {
                                    cursor: pointer;
                                    border-top: 5px solid $white;
                                    border-bottom: 5px solid $white;
                                    background-color: $black;
                                }
                            }

                            &:hover {
                                .swiper-scrollbar {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    #shad.actif {
        top: 0;
    }

    .fp_end_stick_right {
        width: 100%;
        font-size: 0;
    }

    .size_guide_size_wrapper {
        display: flex;
        justify-content: space-between;

        &:has([data-nom="TU"]) {
            display: none;
        }

        .size_qnt {
            width: calc(100% - 135px);
        }

        .block_sizeguide {
            .sizeguide {
                display: inline-block;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-decoration: underline;
                text-underline-position: under;
                text-transform: uppercase;
            }
        }
    }

    /* Guide des tailles */
    #sizeguideBox {
        position: fixed;
        top: 0;
        right: 0;
        transform: translateX(100%);
        z-index: 55;
        transition: transform ease-in-out 0.5s;
        box-sizing: border-box;
        background-color: $white;
        padding: 30px 25px 25px;
        width: 647px;
        height: 100vh;
        height: 100dvh;
        max-height: 100vh;
        max-height: 100dvh;
        overflow: auto;

        .close_pop {
            position: absolute;
            top: 20px;
            right: 18px;
            z-index: 10;
            cursor: pointer;
            border-radius: 0;
            background-image: url(../svg/spritesheet.svg);
            background-color: inherit;
            padding: 0;
            width: 13px;
            height: 13px;
            font-size: 0;
            @include bgIconCoord(30, 8, 8);
            border: none;

            span {
                display: none;
            }
        }

        &.actif {
            display: block !important;
            transform: translateX(0%);
        }

        .switch_gdt_btn {
            margin-bottom: 18px;

            .swiper-slide {
                display: inline-flex;
                width: auto;
            }
        }

        .title_sizeguide {
            margin-bottom: 30px;
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }

        .switch_gdt {
            margin-bottom: 40px;

            @media only screen and (max-width: 1360px) {
                padding: 0;
            }

            .switch_gdt_tables {
                .thumbslide table {
                    tr {
                        td {
                            height: 40px;
                            line-height: 40px;

                            span {
                                height: 40px;
                                line-height: 40px;
                            }

                            &:hover {
                                &:before {
                                    height: 40px;
                                }
                            }
                        }
                    }

                    &:before {
                        left: -1px;
                    }
                }

                .thumbslide {
                    &#content_quide_5 {
                        table {
                            tr {
                                th:nth-of-type(3) {
                                    width: 34%;
                                }
                            }
                        }
                    }

                    &#content_quide_6 {
                        .content_tog_wrapper {
                            flex-wrap: wrap;

                            .content_tog_content {
                                margin-bottom: 25px;
                                width: 100%;
                            }

                            .content_tog_img {
                                margin: 0;
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }

        .sizeguide_contact {
            padding-bottom: 1.25rem;
            text-align: center;

            p {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 1;
                font-family: $primaryFont;

                span {
                    display: block;
                    margin-bottom: 5px;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 1;
                    letter-spacing: 0.7px;
                    text-transform: uppercase;
                }
            }

            a {
                display: inline-block;
                margin-top: 20px;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-align: center;
                text-decoration: underline;
                text-underline-position: under;
                text-transform: uppercase;
            }
        }
    }

    .product_associations_wrapper {
        margin: 0 auto 63px;
        width: 100%;

        .swiper_swap_button {
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }
    }

    .visited_pdt_holder,
    #assoc_rayon_slider {
        .swiper-wrapper {
            display: flex;
            justify-content: flex-start;
        }

        .item {
            &.swiper-slide {
                margin-right: 5px;
                width: 19.79166%;
            }

            .imgWrapper {
                .block_lnk {
                    .wrapper_description {
                        width:calc(100% - 90px);

                        .item_title {
                            width: 100%;
                            overflow: hidden;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 25px;
                            font-family: $primaryFont;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }

                        .item_subtitle {
                            display: block;
                            width: 100%;
                            overflow: hidden;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 25px;
                            font-family: $primaryFont;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                        }
                    }
                }

                .item_price {
                    position: absolute;
                    top: calc(100% - 50px);
                    right: 5px;

                    .pricetag {
                        margin-bottom: 3px;

                        & + .pricetag {
                            color: $grey;
                            text-decoration: line-through;
                        }
                    }
                }

                .wrap_rolloverproduit {
                    bottom: 70px;
                }

                .productVisualMulti {
                    .rollover_rayonsNext,
                    .rollover_rayonsPrev {
                        background-image: url(../svg/spritesheet.svg);
                        @include bgIconCoord(29,8,8);
                        display: block;
                        position: absolute;
                        top: 0;
                        right: auto;
                        bottom: 0;
                        left: 10px;
                        opacity: 0;
                        z-index: 5;
                        cursor: pointer;
                        margin: auto;
                        width: 1rem;
                        height: 1rem;

                        &:after {
                            display: none;
                        }
                    }

                    .rollover_rayonsNext {
                        right: 10px;
                        left: auto;
                        transform: rotate(180deg);
                    }

                    &:hover {
                        .rollover_rayonsNext,
                        .rollover_rayonsPrev {
                            opacity: 1;

                            &.disabled,
                            &.swiper-button-disabled{
                                display: block;
                                opacity: 0.5;
                            }
                        }
                    }
                }
            }
        }
    }

    #productVisitedSwiper {
        margin: 0 auto 90px;
        width: 100%;
        max-width: 765px;

        .swiper-wrapper {
            .item {
                &.swiper-slide {
                    margin-right: 5px;
                    width: calc(50% - 2.5px);
                    max-width: 380px;

                    img {
                        display: block;
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: auto;
                    }
                }

                .imgWrapper {
                    .block_lnk {
                        .wrapper_description {
                            width:calc(100% - 90px);

                            .item_title {
                                width: 100%;
                                overflow: hidden;
                                font-style: normal;
                                font-weight: 600;
                                font-size: 14px;
                                line-height: 20px;
                                font-family: $primaryFont;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }

                            .item_subtitle {
                                display: block;
                                width: 100%;
                                overflow: hidden;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 20px;
                                font-family: $primaryFont;
                                text-overflow: ellipsis;
                                white-space: nowrap;
                            }
                        }
                    }

                    .item_title_wrapper {
                        display: flex;
                        justify-content: space-between;
                        align-items: flex-start;
                    }

                    .item_price {
                        display: flex;
                        position: relative;
                        top: auto;
                        right: auto;
                        flex-direction: row-reverse;
                        padding-right: 7px;
                        min-width: 90px;
                        text-align: right;

                        .pricetag {
                            margin-bottom: 3px;
                            margin-left: 5px;
                            white-space: nowrap;

                            & + .pricetag {
                                color: $grey;
                                text-decoration: line-through;
                            }
                        }
                    }

                    .wrap_rolloverproduit {
                        bottom: 85px;
                    }

                    .productVisualMulti {
                        .rollover_rayonsNext,
                        .rollover_rayonsPrev {
                            display: block;
                            position: absolute;
                            top: 0;
                            right: auto;
                            bottom: 0;
                            left: 10px;
                            z-index: 5;
                            margin: auto;
                            width: 1rem;
                            height: 1rem;
                            @include bgIconPath('../svg/spritesheet.svg');
                            @include bgIconCoord(29,8,8);
                            opacity: 0;
                            cursor: pointer;

                            &:after {
                                display: none;
                            }
                        }

                        .rollover_rayonsNext {
                            right: 10px;
                            left: auto;
                            transform: rotate(180deg);
                        }

                        &:hover {
                            .rollover_rayonsNext,
                            .rollover_rayonsPrev {
                                opacity: 1;

                                &.disabled,
                                &.swiper-button-disabled{
                                    display: block;
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }

        &:not(.swiper-initialized) {
            & > .swiper-wrapper {
                justify-content: center;

                & > .swiper-slide {
                    margin-right: 0;
                }
            }
        }
    }

    .autre_pdt_container {
        margin: 63px auto 0;
        width: 100%;

        &.assoc_rayon,
        &.assoc_product {
            .autre_pdt_tab_list {
                margin-bottom: 0.5rem;
                text-align: center;

                .assocTitle {
                    font-style: italic;
                    font-weight: 400;
                    font-size: 35px;
                    line-height: 1;
                    font-family: $secondaryFont;
                }
            }

            .autres_pdt_holder {
                #assoc_rayon_slider,
                #assoc_product_slider {
                    margin-bottom: 65px;

                    & + #gondole_derniers_articles_vus {
                        margin-top: 110px;
                    }

                    .swiper-wrapper {
                        .swiper-slide {
                            margin-right: 5px;

                            img {
                                display: block;
                                width: 100%;
                                height: auto;
                            }
                        }
                    }

                    .swiper-pagination {
                        top: inherit;
                        right: 0;
                        bottom: 5px;
                        left: 0;
                        opacity: 0;
                        margin: auto;
                        border-radius: 20px;
                        background-color: $white;
                        width: calc(100% - 10px);
                        height: 5px;

                        .swiper-pagination-progressbar-fill {
                            cursor: pointer;
                            border-radius: 20px;
                            background-color: $black;
                        }

                        .swiper-scrollbar-drag {
                            cursor: pointer;
                            background-color: $black;
                        }
                    }

                    .swiper-scrollbar {
                        top: inherit;
                        right: 0;
                        bottom: 5px;
                        left: 0;
                        opacity: 0;
                        opacity: 0;
                        margin: auto;
                        border-radius: 20px;
                        background-color: $white;
                        width: calc(100% - 10px);
                        height: 5px;
                    }

                    .swiper-scrollbar-drag {
                        cursor: pointer;
                        border-radius: 20px;
                        background-color: $black;
                    }

                    &:not(.swiper-initialized) {
                        .swiper-wrapper {
                            display: flex;
                            justify-content: center;
                        }
                    }

                    .productVisualMulti + .description_achat_express_wrapper + .wrap_rolloverproduit {
                        bottom: 75px;
                    }

                    .productVisualMulti {
                        .wrap_rolloverproduit {
                            bottom: 12px;
                        }
                    }

                    #addToWishlistButtonContainer {
                        display: none;
                    }

                    .description_achat_express_wrapper {
                        display: flex;
                        position: relative;
                        flex-direction: column;
                        justify-content: space-between;

                        .description_achat_express_details {
                            display: flex;
                        }

                        .brand_and_description {
                            padding-left: 7px;
                            width: calc(100% - 150px);

                            .wrapper_description {
                                .item_title {
                                    width: 100%;
                                }
                            }
                        }

                        .container_marques {
                            display: block;
                            margin-bottom: 5px;
                            width: calc(100% - 150px);
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 1;
                            font-family: $primaryFont;
                            letter-spacing: .65px;
                            text-transform: uppercase;

                            a {
                                display: block;
                                text-transform: uppercase;
                            }
                        }

                        .wrapper_description {
                            width: 100%;
                        }

                        .item_price {
                            display: flex;
                            flex-direction: row-reverse;
                            flex-wrap: wrap;
                            width: 150px;

                            .pricetag {
                                display: inline-block;
                                height: fit-content;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 15px;
                                line-height: 18px;
                                font-family: $primaryFont;
                                text-align: right;

                                span {
                                    display: inline;
                                }

                                &  + .pricetag {
                                    margin-right: 8px;
                                    padding-top: 1px;
                                    color: $grey;
                                    font-size: 13px;
                                    text-decoration: line-through;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.assoc_product {
            margin-top: 0;
        }
    }

    &.has_sticky_fp {
        padding-bottom: 80px;
    }

    .product_cms_bloc {
        .cms-page-module-body {
            &.text-image-4 {
                margin-bottom: 5px;
            }
        }
    }
}

/* Sticky */
.product_info.sticky_bar_fp {
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: space-between;
    align-items: center;
    z-index: 8;
    box-sizing: border-box;
    border-top: 1px solid rgba($black, 0.025);
    background-color: $beige-clair;
    padding: 15px 6.25vw;
    height: 81px;
    width: 100%;
    animation: slideUpStickyFp 0.5s forwards;

    .sticky_wrapper_right .itm_form_group {
        height: auto !important;

        .disabledBtnInfos {
            margin: 0!important;
        }
    }

    @media only screen and (max-width: 1480px) {
        padding: 15px 2.25vw;
    }

    .size_guide_size_wrapper {
        .size_qnt {
            width: 100%;
        }
    }

    .boutique_n_list_wrapper {
        display: none !important;
    }

    .wrapper_name_price {
        margin-bottom: 0 !important;
        padding-right: 10px;
    }

    .sticky_wrapper_right {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .productSizeFieldset {
        .choices_list {
            padding-bottom: 0 !important;

            .form_itm {
                input[type=radio] {
                    & + label {
                        border-color: $beige-clair !important;
                        padding: 0 !important;
                    }

                    &:checked {
                        & + label {
                            border-color: $black !important;
                        }
                    }
                }
            }
        }
    }

    .frznocache,
    div[itemprop="offers"],
    .productColorFieldset,
    .block_sizeguide,
    #ligne_qte,
    .info_magasin,
    .wishlist_brands_review_wrapper {
        display: none !important;
    }

    #product_addcart,
    [id^="product_addcart_"] {
        width: 415px;

        @media only screen and (max-width: 1180px) {
            width: 280px;
        }

        #bloc_add_basket {
            margin: 0 !important;
            width: 100%;

            #btn_add_cart {
                margin: 0 !important;

                &.btnAddBasket[disabled] {
                    opacity: 1;
                }

                &:hover {
                    background-color: $beige-clair !important;
                    color: $black !important;
                }

                & + .loader {
                    top: 0 !important;
                }
            }
        }

        #btn_alert_stock {
            margin: 0 !important;
            background-color: $beige-clair !important;
            width: 100%;
            color: $black !important;

            &.form_visible {
                display: none;
            }
        }

        .disabledBtnTooltip {
            right: 0;
            left: 0;
            margin: auto;
            background-color: transparent;
            text-align: center;
            opacity: 0;
            transition: opacity 0.20s ease-in-out;

            &.actif {
                opacity: 1;
            }

            .disabledBtnInfos {
                text-align: center !important;
            }
        }

        .pdvWrapper,
        #giftListAdd {
            display: none !important;
        }
    }

    .price_container {
        display: none !important;

        &.for_sticky {
            display: flex !important;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            margin: 0 80px 0 59px;

            @media only screen and (max-width: 1180px) {
                margin: 0 40px;
            }

            div.price {
                position: relative;
                margin-left: 12px;
                height: 18px;
                font-style: normal;
                font-weight: 400;
                font-size: 17px;
                line-height: 1;
                font-family: $primaryFont;
                text-align: right;

                & + div.price {
                    color: $grey;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 15px;
                    line-height: normal;
                    font-family: $primaryFont;
                    text-align: right;

                    &:before {
                        position: absolute;
                        top: 0;
                        bottom: 0;
                        margin: auto;
                        background-color: $grey;
                        width: 100%;
                        height: 1px;
                        content: '';
                    }
                }
            }
        }
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 40px $beige-clair inset !important;
    }

    #bloc_add_alert {
        margin-bottom: 0;

        .bloc_add_alert_confirmation {
            font-size: 13px !important;
        }

        #keep_alive_stock {
            #bloc_add_alert_form,
            & + .bloc_add_alert_confirmation {
                margin: 0 !important;
            }
        }
    }

    .product_addcart_look {
        margin-bottom: 0 !important;
    }

    .product_option_wrapper {
        text-wrap: nowrap;
        flex-direction: row;
        gap: 1rem;

        .product_option {
            gap: 1rem;
            padding: 0 0.5rem 0 0;
        }
    }
}

@media only screen and (max-width: 1280px) {
    .product_info.sticky_bar_fp {
        height: 120px;
        animation: slideUpStickyFp1280 0.5s forwards;

        .sticky_wrapper_right {
            flex-wrap: wrap;

            #product_selects {
                display: flex;
                justify-content: flex-end;
                margin-bottom: 10px;
                width: 100%;
            }
        }
    }
}

/* Block Disponible En Magasin  */
#pdvBox,
#availabilityInStore {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    transform: translateX(100%);
    opacity: 1;
    z-index: 55;
    transition: transform ease-in-out 0.5s;
    box-sizing: border-box;
    background-color: $white;
    padding: 30px 25px 25px;
    width: 380px;
    height: 100dvh;

    .close_pop {
        position: absolute;
        top: 20px;
        right: 18px;
        z-index: 10;
        cursor: pointer;
        border-radius: 0;
        background-image: url(../svg/spritesheet.svg);
        background-color: inherit;
        padding: 0;
        width: 13px;
        height: 13px;
        font-size: 0;
        @include bgIconCoord(30, 8, 8);
        border: none;

        span {
            display: none;
        }
    }

    &.actif {
        transform: translateX(0%);
    }

    #content_pdvBox,
    #stepStoreList {
        .popup_title,
        .availabilityInStoreTitle {
            display: block;
            margin-bottom: 22px;
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }

        .availabilityInStoreDescription {
            display: none;
        }

        #storeListForm {
            margin-bottom: 30px;

            .formWrapper {
                .field_address {
                    position: relative;

                    #searchAddress {
                        box-sizing: border-box;
                        border: none;
                        border-bottom: 1px solid $grey;
                        border-radius: 0;
                        padding: 0 2px;
                        width: 100%;
                        height: 40px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;

                        & + button {
                            position: absolute;
                            top: 10px;
                            right: 0;
                            cursor: pointer;
                            border: none;
                            background-image: url('../svg/spritesheet.svg');
                            background-color: $white;
                            padding: 0;
                            width: 20px;
                            height: 20px;
                            @include bgIconCoord(17, 8, 8);

                            span {
                                display: none;
                            }
                        }
                    }
                }
            }
        }

        .magasin_container {
            .store_locator {
                .storeloc_form {
                    margin-bottom: 30px;
                }

                .form_itm {
                    &.country_select {
                        display: none;
                    }

                    &.zip_input {
                        div {
                            position: relative;

                            #search_cp {
                                box-sizing: border-box;
                                border: none;
                                border-bottom: 1px solid $grey;
                                border-radius: 0;
                                padding: 0 2px;
                                width: 100%;
                                height: 40px;
                                color: $black;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 14px;
                                line-height: 18px;
                                font-family: $primaryFont;
                            }

                            #btn_geoloc {
                                position: absolute;
                                top: 10px;
                                right: 0;
                                cursor: pointer;
                                border: none;
                                background-color: $white;
                                padding: 0;
                                width: 20px;
                                height: 20px;

                                &:before {
                                    width: 20px;
                                    height: 20px;
                                }
                            }
                        }
                    }
                }
            }
        }

        #liste_distributeur_wrapper,
        #storeListScrollWrapper {
            max-height: calc(100vh - 200px);

            #liste_distributeur,
            .liste_distributeur_scrollbar {
                .elem_liste_distributeur {
                    position: relative;
                    margin-bottom: 22px;
                    border-bottom: 1px solid $lightgrey;
                    padding-bottom: 22px;
                    font-family: $primaryFont;

                    .nom_store,
                    .store_name {
                        margin-bottom: 6px;
                        width: 230px;
                        overflow: hidden;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        letter-spacing: 0.7px;
                        text-overflow: ellipsis;
                        text-transform: uppercase;
                        white-space: nowrap;

                        .distance {
                            display: none;
                        }
                    }

                    .store_details {
                        .store_address {
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;

                            span {
                                display: block;
                            }
                        }

                        .see_map {
                            display: none;
                        }
                    }

                    .store_phone,
                    .store_infos.phone {
                        display: flex;
                        align-items: center;
                        margin: 6px 0 10px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                        text-decoration: underline;
                        text-underline-position: under;

                        &:before {
                            background-image: url('../svg/spritesheet.svg');
                            width: 15px;
                            height: 15px;
                            content: '';
                            @include bgIconCoord(18, 8, 8);
                            margin-right: 6px;
                        }
                    }


                    .store_infos {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                    }

                    .horaire_today,
                    .store_open_hours {
                        display: block !important;
                        margin: 10px 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: normal;

                        .puce {
                            display: inline-block;
                            position: relative;
                            padding-left: 12px;
                            height: 10px;

                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 1px;
                                left: 0;
                                content: '•';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 30px;
                                line-height: 10px;
                                font-family: sans-serif;
                            }

                            &.store_open {
                                &:before,
                                + .mag_open_status {
                                    color: $green;
                                }
                            }

                            &.store_closed {
                                &:before,
                                + .mag_open_status {
                                    color: $red;
                                }
                            }
                        }

                        .txt_store_closed {
                            color: $red;
                        }

                        .txt_store_open {
                            position: relative;
                            padding-left: 13px;
                            color: $green;

                            &:before {
                                display: inline-block;
                                position: absolute;
                                top: 1px;
                                left: 0;
                                content: '•';
                                font-style: normal;
                                font-weight: 300;
                                font-size: 30px;
                                line-height: 10px;
                                font-family: sans-serif;
                            }
                        }
                    }

                    .link_store_wrapper {
                        .schedule_wrapper {
                            .show_schedule {
                                cursor: pointer;
                                font-style: normal;
                                font-weight: 400;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                text-decoration: underline;
                                text-underline-position: under;

                                & + .store_week_schedule {
                                    display: none;
                                    padding-top: 15px;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                }
                            }
                        }
                    }

                    .horaire_toggle {
                        cursor: pointer;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        text-decoration: underline;
                        text-underline-position: under;
                    }

                    .bloc_horaire {
                        display: none;
                        padding: 15px 0;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 11px;
                        line-height: 18px;
                        font-family: $primaryFont;

                        .currently_open {
                            color: $green;
                            font-weight: 600;
                        }
                    }

                    .addfavorite {
                        display: none;
                    }

                    .mag_stock_fav,
                    .storeStock {
                        display: inline-block;
                        position: absolute;
                        top: 0;
                        right: 6px;
                        bottom: 0;
                        margin: auto;
                        width: auto;
                        height: 18px;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 1;
                        font-family: $primaryFont;
                        text-transform: initial;

                        &:before {
                            display: inline-block;
                            position: relative;
                            top: 2px;
                            margin-right: 6px;
                            background-image: url(../svg/spritesheet.svg);
                            width: 1rem;
                            height: 1rem;
                            content: '';
                        }

                        &[data-status="3"],
                        &.high {
                            &:before {
                                @include bgIconCoord(39,8,8);
                            }
                        }

                        &[data-status="2"],
                        &.medium {
                            &:before {
                                @include bgIconCoord(40,8,8);
                            }
                        }

                        &[data-status="1"],
                        &.low {
                            &:before {
                                @include bgIconCoord(41,8,8);
                            }
                        }
                    }

                    .button.secondary {
                        @media only screen and (max-width: 1260px) {
                            font-size: 11px;
                            letter-spacing: 0.2px;
                        }

                        @media only screen and (max-width: 1160px) {
                            font-size: 10px;
                        }

                        @media only screen and (max-width: 1060px) {
                            font-size: 9px;
                        }
                    }
                }
            }
        }
    }
}

/* Liste de naissance FP */
#lightboxGlAddToList {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    left: auto;
    transform: translateX(100%);
    opacity: 1;
    z-index: 200;
    transition: transform ease-in-out 0.5s;
    box-sizing: border-box;
    background-color: $white;
    width: 100%;
    max-width: 380px;
    height: 100vh;
    height: 100dvh;
    text-align: center;

    &.actif {
        transform: translateX(0%);
    }

    #glAddToList {
        position: relative;
        padding: 25px 10px 25px 25px;
        width: 100%;
        height: 100%;

        .close {
            cursor: pointer;
            background-image: url('../svg/spritesheet.svg');
            width: 11px;
            height: 11px;
            @include bgIconCoord(30, 8, 8);
            display: block;
            position: absolute;
            top: 12px;
            right: 15px;
            margin-left: 8px;
            color: transparent;
        }

        .gl_title {
            margin-bottom: 22px;
            color:$black;
            font-style: italic;
            font-weight: 400;
            font-size: 25px;
            line-height: 30px;
            font-family: $secondaryFont;
            text-align: center;
        }

        .gl_subtitle,
        .gl_no_list {
            margin-bottom: 30px;
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;
            text-align: center;
        }

        .gl_create {
            position: absolute;
            right: 0;
            bottom: 25px;
            left: 0;
            color: $black;
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: normal;
            font-family: $primaryFont;
            letter-spacing: 0.6px;
            text-decoration: underline;
            text-transform: uppercase;
        }

        #formGlAddToList {
            height: calc(100vh - 280px);
            height: calc(100dvh - 280px);
            overflow: hidden;

            .wrapped_details {
                padding-right: 15px;
                height: 100%;
                overflow: auto;
            }

            .gl_details {
                border-top: 1px solid $lightgrey;

                &:first-child {
                    border-top: none;
                }

                .gl_line {
                    display: flex;
                    position: relative;
                    padding: 30px 0;

                    &.infos {
                        position: relative;
                        top: -10px;
                        justify-content: center;
                        padding: 0 0 15px;
                        color: $green;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        font-family: $primaryFont;
                    }

                    input[type="checkbox"] {
                        position: relative;
                        cursor: pointer;
                        padding-left: 30px;
                        width: 15px;
                        height: 15px;
                    }

                    input[type="checkbox"] {
                        &:before {
                            position: absolute;
                            top: 0;
                            left: 0;
                            border: 1px solid $black;
                            background-color: $white;
                            width: 15px;
                            height: 15px;
                            content: '';
                        }
                    }

                    input[type="checkbox"]:checked {
                        &:before {
                            box-shadow: 2px 2px 0px $white inset, -2px -2px 0px $white inset;
                            background-color: $black;
                        }
                    }

                    label {
                        display: flex;
                        flex-direction: column;
                        margin-left: 25px;

                        .gl_name,
                        .gl_date {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 21px;
                            font-family: $primaryFont;
                            letter-spacing: 0.7px;
                            text-align: left;
                            text-transform: uppercase;
                        }
                    }

                    .gl_goto {
                        @include bgIconPath('../svg/goto.svg');
                        display: block;
                        position: absolute;
                        right: 5px;
                        background-size: 100%;
                        width: 15px;
                        height: 15px;
                        content: '';
                    }
                }
            }

            .gl_add_product {
                display: block;
                position: absolute;
                bottom: 50px;
                margin: 0 auto 22px;
                border-radius: 50px;
                background-color: $black;
                padding: 18px 0;
                width: 100%;
                max-width: 350px;
                color: $white;
                font-style: normal;
                font-weight: 500;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-align: center;
                text-transform: uppercase;

                &.inactiv {
                    opacity: 0.5;
                    cursor: default;
                }
            }

            #gl_lightbox_loader {
                position: absolute;
                top: 25px;
                left: 0;
                opacity: 0;
                z-index: -1;
                border-radius: 50px;
                background: $black url(../svg/three_dots.svg) no-repeat center;
                background-size: 15% auto;
                width: 100%;
                max-width: 350px;
                height: 48px;

                .loading {
                    opacity: 1;
                    z-index: 1;
                }
            }
        }
    }
}

.product_option_wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    width: 100%;

    .product_option {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: var(--opt-color, $beige-clair);
        padding: 0.5rem;
        width: 100%;
        font-size: 0.9rem;

        input[type="checkbox"] {
            -webkit-appearance: none;
            appearance: none;
            cursor: pointer;
            outline: none;
            border: 1px solid $beige_Fonce;
            border-radius: 3px;
            width: 15px;
            height: 15px;
        }

        input[type="checkbox"]:checked {
            background-color: rgba($black, 0.025);
        }

        input[type="checkbox"]:checked::before {
            display: block;
            content: '\2713';
            color: $beige_Fonce;
            font-size: 12px;
            line-height: 15px;
            text-align: center;
        }

        .wrapper_checkbox_product_option {
            display: flex;
            align-items: center;
            gap: 0.25rem;
            font-size: 0.8rem;

            .info {
                cursor: pointer;
                margin-bottom: 0.1rem;
                background-image: url(../svg/info.svg);
                width: 0.8rem;
                height: 0.8rem;
            }
        }

        &+.lightbox {
            display: block;
            left: unset;
            opacity: 1;
            z-index: 30;
            text-align: center;

            .close {
                display: none;
            }

            .title {
                margin-bottom: 1rem;
                font-style: italic;
                font-size: 1.75rem;
                font-family: $secondaryFont;
            }

            .description {
                padding: 0 1.5rem;
                font-size: 0.9rem;
                line-height: 1.5;
                font-family: $primaryFont;
            }

            &.actif {
                transform: translateX(0);
            }
        }
    }
}

/* Define the animation */
@keyframes marquee2 {
    0% {
      left: 0;
    }

    100% {
      left: -100%;
    }
}

@keyframes slideUpStickyFp {
    from {
        bottom: -81px;
    }
    to {
        bottom: 0;
    }
}

@keyframes slideUpStickyFp1280 {
    from {
        bottom: -120px;
    }
    to {
        bottom: 0;
    }
}

@keyframes blinkBtn{
    0%{opacity: .25;}
    20%{opacity: .5;}
    100%{opacity: .25;}
}