/* liste de naissance */
body.gift_list_index {
    #breadcrumbs {  
        position: absolute;      
        top: 135px;
        z-index: 10;

        .breadcrumbs {
            .breadcrumb,
            a {
                color: $white;
            }
        }
    }
    
    .gl_faq_title {
        margin-bottom: 40px;
        padding: 0 6.25vw;
        color: $black;
        font-style: italic;
        font-weight: 400;
        font-size: 35px;
        line-height: 35px;
        font-family: $secondaryFont;
        text-align: center;
    }

    .gl_faq_element  {
        margin: auto;
        border-bottom: 2px solid $lightgrey;
        width: 100%;
        max-width: 1280px;

        @media screen and(max-width:1024px) {
            width: 95%;
        }

        .faq_question {
            position: relative;
            cursor: pointer;
            border-top: 2px solid $lightgrey;
            font-weight: 500;
            font-size: 12px;
            line-height: 45px;
            font-family: $primaryFont;
            letter-spacing: 0.05em;
            text-align: left;

            &:after {
                background-image: url('../svg/spritesheet.svg');
                content: '';
                @include bgIconCoord(29,8,8);
                display: inline-block;
                position: absolute;
                top: 19px;
                right: 0;
                transform: rotate(-90deg);
                transition: all 0.35s ease-in-out;
                width: 10px;
                height: 12px;
            }

            &.active {
                &:after {
                    transform: rotate(-270deg);
                }
            }
        }

        .gl_faq_reponse  {
            padding-bottom: 20px;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;
            letter-spacing: 0em;
            text-align: left;
        }
    }

    .gl_faq_cta {
        display: block;
        margin-top: 20px;
        width: 100%;
        color: $black;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        font-family: $primaryFont;
        letter-spacing: 0.6px;
        text-align: center;
        text-decoration: underline;
        text-transform: uppercase;
    }

    .naissance_4_blocs {
        margin: 0 auto 63px;
        padding: 0 6.25vw;

        .title {
            margin-bottom: 27px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;
        }

        .bloc_4_wrapper {
            display: flex;
            justify-content: center;

            .bloc {
                box-sizing: border-box;
                margin-right: 13px;
                background: $beige-clair;
                padding: 38px 36px 30px;
                width: 25%;
                max-width: 310px;
                text-align: center;

                .title_bloc {
                    margin-bottom: 0.5rem;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 1rem;
                    line-height: 1;
                    font-family: $primaryFont;
                    letter-spacing: 0.8px;
                    text-transform: uppercase;

                    span {
                        padding-right: 5px;
                    }
                }

                .text_bloc {
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                &:last-of-type {
                    margin-right: 0;
                }
            }
        }
    }

    .blog_naissance {
        position: relative;
        width: 100%;

        .naissance_picture {
            img {
                display: block;
                width: 100%;
                height: auto;
            }
        }

        .blog_naissance_text {
            display: flex;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            flex-wrap: wrap;
            align-content: center;
            justify-content: center;
            z-index: 2;
            margin: auto;
            width: 100%;
            height: 100%;
            text-align: center;

            .title {
                margin-bottom: 20px;
                width: 100%;
                color: $white;
                font-style: italic;
                font-weight: 400;
                font-size: 35px;
                line-height: 35px;
                font-family: $secondaryFont;
                letter-spacing: 0em;
                text-align: center;
            }

            .blog_naissance_wrapper {
                display: flex;
                justify-content: center;
                width: 100%;

                a {
                    margin-right: 50px;
                    color: $white;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: 14px;
                    font-family: $primaryFont;
                    letter-spacing: 0.05em;
                    text-align: center;
                    text-decoration: underline;
                    text-transform: uppercase;

                    &:last-child {
                        margin-right: 0;
                    }
                }
            }
        }
    }

    .naissance_text {
        display: block;
        margin: 20px auto 50px;
        width: 100%;
        max-width: 980px;
        color: $black;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
        font-family: $primaryFont;
        text-align: center;
    }

    .liste_naissance {
        margin: 0 auto 70px;

        .title_naissance {
            position: relative;
            margin-bottom: 17px;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 1;
            font-family: $secondaryFont;
            text-align: center;

            .swiper_wrapper_buttons {
                display: flex;
                position: absolute;
                top: 0;
                right: 11px;
                bottom: 0;
                justify-content: space-between;
                align-items: center;
                margin: auto;
                width: 72px;

                .tete_de_gondole-button-prev,
                .tete_de_gondole-button-next {
                    background-image: url('../svg/spritesheet.svg');
                    @include bgIconCoord(29,8,8);
                    cursor: pointer;
                    width: 1rem;
                    height: 1rem;

                    &.swiper-button-lock {
                        display: none;
                    }

                    &.swiper-button-disabled {
                        display: block;
                        opacity: 0.5;
                    }
                }

                .tete_de_gondole-button-next {
                    transform: rotate(180deg);
                }
            }
        }

        .liste_naissance_slider_wrapper {
            .liste_naissance_slider_container {
                .swiper-wrapper {
                    display: flex;
                    margin-bottom: 20px;
                    height: auto;

                    .swiper-slide {
                        position: relative;
                        margin-right: 5px;
                        width: 33.125%;

                        img {
                            display: block;
                            width: 100%;
                            height: auto;
                        }

                        .description {
                            display: flex;
                            width: 100%;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            flex-direction: column;
                            flex-wrap: wrap;
                            align-content: center;
                            justify-content: center;
                            align-items: center;
                            transform: translateX(-50%);
                            text-align: center;

                            .titre_birth {
                                margin-bottom: 22px;
                                color: $white;
                                font-style: italic;
                                font-weight: 400;
                                font-size: 35px;
                                line-height: 35px;
                                font-family: $secondaryFont;
                                text-align: center;
                            }

                            .cta_birth {
                                font-style: normal;
                                font-weight: 500;
                                font-size: 12px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.6px;
                                text-align: center;
                                text-transform: uppercase;
                                
                                .cta {
                                    color: $white;
                                    text-decoration: underline;
                                }
                            }
                        }
                    }
                }
            }

            .swiper-pagination {
                top: auto;
                right: 0;
                bottom: 0;
                left: 0;
                margin: auto;
                border-radius: 20px;
                background-color: $white;
                width: calc(100% - 20px);
                height: 4px;
                overflow: hidden;

                .swiper-scrollbar-drag {
                    cursor: pointer;
                    background-color: $black;
                }
            }
        }
    }
}

body.gift_list_search {
    .wrapper_gift_list {
        display: flex;
        align-items: center;
        padding: 0 clamp(1rem, fs-vw(30, 1920), 30px);
        width: 100%;

        @media only screen and (max-width: 1440px) {
            padding: 0 2vw;
        }

        .wrapper_search {
            width: 100%;
            max-width: 670px;

            .gift_list_title {
                margin-bottom: 22px;
                color: $black;
                font-style: italic;
                font-weight: 400;
                font-size: 35px;
                line-height: 35px;
                font-family: $secondaryFont;
                text-align: center;
            }

            .gift_list_subtitle {
                margin-bottom: 15px;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                font-family: $primaryFont;
                text-align: center;
            }

            #gift_list_search {
                position: relative;
                border-bottom: 1px solid $grey;

                #gl_search_keyword {
                    border: none;
                    width: 88%;
                    color: $grey;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                button {
                    border: none;
                    background-color: transparent;
                    height: 100%;
                    color: $white !important;

                    &:hover {
                        color: $white;
                    }

                    &:after {
                        display: block;
                        position: absolute;
                        background-image: url('../svg/spritesheet.svg');
                        width: 15px;
                        height: 15px;
                        content: '';
                        @include bgIconCoord(1,8,8);
                        top: 0;
                        right: 0;
                    }
                }
            }

            #gift_list_results {
                margin-top: 25px;
                max-height: 22rem;
                overflow: hidden;
                overflow-y: auto;

                .gl_search_list {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $lightgrey;
                    padding: 25px 0;
                    width: 98%;

                    &:first-of-type {
                        padding: 0 0 25px;
                    }

                    .infos {
                        .title {
                            color: $black;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 20px;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-transform: uppercase;
                        }

                        .creator {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 18px;
                            font-family: $primaryFont;
                        }
                    }

                    .lien {
                        display: flex;
                        align-items: center;
                        color: $black;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 20px;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-align: right;
                        text-transform: uppercase;

                        a {
                            text-decoration: underline;
                        }
                    }
                }

                .gl_search_no_result {
                    margin-top: 10px;
                    color: $red;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                &:has(.gl_search_no_result) {
                    margin-top: 0;
                }
            }
        }
    }

    .wrapper_bandeau_search {
        margin-right: 12%;

        @media screen and(max-width:1024px) {
            margin-right: 15px;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}

body.gift_list_show {
    .giftlist_infos {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .gl_picture,
        .gl_no_picture_wrapper {
            display: flex;
            justify-content: center;
            margin-bottom: 15px;

            img,
            .gl_no_picture {
                border-radius: 120px;
                width: 170px;
                height: 170px;
            }

            .gl_no_picture {
                background-image: url('../img/profile_image.png');
                background-size: contain;
            }
        }

        .gl_title {
            color: $black;
            font-style: italic;
            font-weight: 400;
            font-size: 35px;
            line-height: 35px;
            font-family: $secondaryFont;
            text-align: center;
            text-transform: inherit;
        }

        .gl_created {
            color: $grey;
            font-style: normal;
            font-weight: 400;
            font-size: 15px;
            line-height: 25px;
            font-family: $primaryFont;
            letter-spacing: 0.75px;
            text-align: center;

            .gl_creator_name {
                text-transform: uppercase;
            }
        }

        .gl_date {
            color: $grey;
            font-style: normal;
            font-weight: 400;
            font-size: 11px;
            line-height: 18px;
            font-family: $primaryFont;
            text-align: center;
        }

        .gl_description {
            margin: 30px 0 40px;
            width: 100%;
            max-width: 1280px;
            overflow: hidden;
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            font-family: $primaryFont;
            text-align: center;
            text-overflow: ellipsis;
            white-space: nowrap;
        }
    }

    .gl_back_button {
        position: relative;
        margin-left: 5px;

        a {
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: normal;
            font-family: $primaryFont;
            text-decoration-line: underline;
        }

        &:before {
            display: block;
            position: absolute;
            top: 4px;
            left: -15px;
            background-image: url('../svg/spritesheet.svg');
            width: 8px;
            height: 10px;
            content: '';
            @include bgIconCoord(29,8,8);
        }
    }

    .list_regroup_content {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        column-gap: 5px;
        row-gap: 30px;

        .item_product {
            .gl_infos {
                .gl_details {
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;
                    margin-top: 10px;

                    .gl_left_wrapper {
                        margin-left: 5px;

                        .container_marques {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 13px;
                            line-height: 18px;
                            font-family: $primaryFont;
                            letter-spacing: 0.04rem;
                            text-transform: uppercase;
                        }

                        .gl_title,
                        .gl_subtitle {
                            max-width: 212px;
                            overflow: hidden;
                            color: $black;
                            font-weight: 600;
                            font-size: 14px;
                            line-height: 20px;
                            font-family: $primaryFont;
                            text-overflow: ellipsis;
                            white-space: nowrap;

                            @media screen and(max-width:1024px) {
                                max-width: 12rem;
                            }
                        }

                        .gl_subtitle {
                            font-weight: 400;
                        }
                    }

                    .gl_right_wrapper {
                        .gl_price {
                            margin-bottom: 20px;

                            .bloc_price {
                                display: flex;
                                justify-content: flex-end;

                                .price_tag {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 15px;
                                    line-height: normal;
                                    font-family: $primaryFont;

                                    +.price_tag {
                                        order: -1;
                                        margin-right: 10px;
                                        color: $grey;
                                        text-decoration: line-through;
                                    }
                                }
                            }
                        }

                        .gl_action {
                            bottom: 3px;
                            cursor: pointer;
                            color: $black;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-align: right;
                            text-decoration: underline;
                            text-transform: uppercase;
                        }
                    }
                }

                .gl_img {
                    position: relative;

                    img {
                        display: block;
                        width: 100%;
                        height: auto;
                    }

                    .text_item_buy {
                        position: absolute;
                        top: 50%;
                        left: 40%;             
                        color: $black;
                        font-style: normal;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: normal;
                        font-family: $primaryFont;
                        letter-spacing: 0.6px;
                        text-transform: uppercase;

                        @media screen and(max-width:1024px) {
                            left: 30%;
                        }
                    }
                }
            }

            &.bought {
                .gl_infos {
                    .gl_details {
                        .gl_left_wrapper,
                        .gl_right_wrapper {
                            .container_marques,
                            .gl_title,
                            .gl_subtitle,
                            .price_tag {
                                opacity: 0.5;
                                color: $black;
                            }
                        }
                    }

                    .gl_img {
                        position: relative;

                        .gl_bought {
                            position: absolute;
                            top: 50%;
                            left: 0;
                            transform: translateY(-50%);
                            width: 100%;
                            font-size: 9px;
                            text-align: center;
                        }

                        img {
                            opacity: 0.5;
                        }
                    }
                }
            }
        }

        #free_contribution {
            .gl_infos {
                .gl_img {
                    cursor: pointer;
                }
                .gl_details {
                    .gl_right_wrapper {
                        .gl_action {
                            position: absolute;

                            .gl_btn_participate,
                            .gl_participate {
                                position: relative;
                                right: 75px;
                            }
                        }
                    }
                }
            }
        }

        .lightbox {
            position: fixed;
            top: 0;
            right: 0;
            left: auto;
            transform: translateX(0%);
            transition: all .3s ease-in-out;
            box-sizing: border-box;
            background-color: $white;
            padding: 30px;
            width: 100%;
            max-width: 380px;
            height: 100%;
            text-align: center;

            .close {
                cursor: pointer;
                background-image: url('../svg/spritesheet.svg');
                width: 11px;
                height: 11px;
                @include bgIconCoord(30, 8, 8);
                display: block;
                position: absolute;
                top: 12px;
                right: 15px;
                margin-left: 8px;
                color: transparent;
            }

            .container_marques {
                margin: 12px 0;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 13px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.04rem;
                text-align: left;
                text-transform: uppercase;
            }

            .gl_title_price {
                display: flex;
                justify-content: space-between;

                .gl_title {
                    overflow: hidden;
                    color: $black;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                    line-height: 25px;
                    font-family: $primaryFont;
                    text-align: left;
                }

                .gl_price {
                    .bloc_price {
                        display: flex;

                        .price_tag {
                            color: $black;
                            font-style: normal;
                            font-weight: 400;
                            font-size: 15px;
                            line-height: normal;
                            font-family: $primaryFont;
                            text-align: right;

                            + .price_tag {
                                order: -1;
                                margin-right: 5px;
                                color: $grey;
                                text-decoration: line-through;
                            }
                        }
                    }
                }
            }

            .gl_subtitle {
                overflow: hidden;
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 20px;
                line-height: 25px;
                font-family: $primaryFont;
                text-align: left;
            }

            .gl_color_size {
                display: flex;

                .gl_color,
                .gl_size {
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                .gl_color {
                    display: flex;

                    &::after {
                        display: block;
                        margin: 0 10px;
                        content: ' | ';
                        color: $beige-clair;
                    }
                }
            }

            .w-giftlist-contribution-form {
                .w-form-line {
                    margin-bottom: 0;

                    h2 {
                        margin: 30px 0 5px;
                        color: $black;
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 21px;
                        font-family: $primaryFont;
                        letter-spacing: 0.7px;
                        text-transform: uppercase;
                    }

                    .w-input-container {
                        .w-input {
                            .w-input-label {
                                span {
                                    color: $grey;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                }
                            }

                            &.w-text-input {
                                margin-bottom: 15px;
                            }

                            &.w-textarea {
                                .w-input-element {
                                    padding: 30px 2px 0;
                                    height: 4em;
                                }

                                &.w-nonempty {
                                    .w-input-label {
                                        display: none;
                                    }
                                }
                            }
                        }
                    }

                    &.w-submit {
                        margin: 25px auto 0;
                        width: 100%;
                        max-width: 350px;

                        .w-submit-button {
                            margin-bottom: 30px;
                            background-color: $black;
                            color: $white;

                            span {
                                color: $white;
                                font-style: normal;
                                font-weight: 500;
                                font-size: 13px;
                                line-height: normal;
                                font-family: $primaryFont;
                                letter-spacing: 0.04rem;
                                text-align: center;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }

            .swiper {
                .swiper-slide {
                    .block_lnk {
                        .ill_img,
                        .no_lazy {
                            img {
                                width: 328px;
                                height: 410px;
                            }
                        }
                    }
                }

                .swiper_container_buttons {
                    display: block !important;

                    .swiper-button-next,
                    .swiper-button-prev {
                        background-image: url('../svg/spritesheet.svg');
                        @include bgIconCoord(29,8,8);
                        display: block;
                        position: absolute;
                        top: 0;
                        right: auto;
                        bottom: 0;
                        left: 10px;
                        opacity: 1;
                        z-index: 5;
                        cursor: pointer;
                        margin: auto;
                        width: 1rem;
                        height: 1rem;

                        &:after {
                            display: none;
                        }

                        &.swiper-button-disabled {
                            opacity: 0.35;
                            cursor: default;
                        }
                    }

                    .swiper-button-next {
                        right: 0px;
                        left: auto;
                        transform: rotate(180deg);
                    }

                    .swiper-button-prev {
                        left: 0px;
                    }
                }
            }
        }
    }

    #gl_products_div {
        margin-bottom: 70px;

        .gl_nb_product {
            margin-bottom: 22px;
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            font-family: $primaryFont;
            letter-spacing: 0.7px;
            text-transform: uppercase;
        }

        .desc_pannel {
            border-top: 1px solid $lightgrey;

            &:last-child {
                border-bottom: 1px solid $lightgrey;
            }

            .toggleCarac {
                position: relative;
                margin: 20px auto;
                color: $black;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: 20px;
                font-family: $primaryFont;
                letter-spacing: .6px;
                text-transform: uppercase;

                &:after {
                    background-image: url('../svg/spritesheet.svg');
                    content: '';
                    @include bgIconCoord(29,8,8);
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    right: 0;
                    transform: rotate(-90deg);
                    transition: all 0.35s ease-in-out;
                    width: 10px;
                    height: 12px;
                }

                &.actif {
                    &:after {
                        transform: rotate(-270deg);
                    }

                    > .content {
                        display: flex !important;
                        gap: 2rem;
                    }
                }
            }

            .content {
                display: flex;
                position: relative;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: center;
                padding-bottom: 60px;

                &.actif {
                    display: flex !important;
                }

                .item_product {
                    position: relative;
                    margin-right: 12px;
                    margin-bottom: 30px;
                    width: calc((100% - 36px) / 4);

                    &:nth-child(4n) {
                        margin-right: 0;
                    }

                    @media screen and(max-width:1024px) {
                        margin-right: 12px;
                    }

                    .gl_delete {
                        display: block;
                        position: absolute;
                        right: 0px;
                        background-image: url('../svg/spritesheet.svg');
                        content: '';
                        @include bgIconCoord(30,8,8);
                        cursor: pointer;
                        width: 11px;
                        height: 11px;
                        color: transparent;
                    }

                    a {
                        .gl_infos {
                            display: flex;

                            .gl_details {
                                margin-left: 12px;

                                .container_marques {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 13px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                    letter-spacing: 0.04rem;
                                    text-transform: uppercase;
                                }

                                .gl_title,
                                .gl_subtitle {
                                    max-width: 170px;
                                    overflow: hidden;
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 600;
                                    font-size: 14px;
                                    line-height: 18px;
                                    font-family: $primaryFont;
                                    text-overflow: ellipsis;
                                    white-space: nowrap;

                                    @media screen and(max-width:1024px) {
                                        max-width: 100px;
                                    }
                                }

                                .gl_subtitle {
                                    font-weight: 400;
                                }

                                .gl_price {
                                    margin-bottom: 32px;

                                    .bloc_price {
                                        display: flex;

                                        .price_tag {
                                            .price {
                                                color: $black;
                                                font-style: normal;
                                                font-weight: 400;
                                                font-size: 15px;
                                                line-height: normal;
                                                font-family: $primaryFont;
                                            }

                                            + .price_tag {
                                                order: -1;
                                                margin-right: 8px;
                                                color: $grey;
                                                text-decoration: line-through;
                                                
                                                .price {
                                                    color: $grey;
                                                }
                                            }
                                        }
                                        
                                    }
                                }

                                .gl_color,
                                .gl_size {
                                    color: $black;
                                    font-style: normal;
                                    font-weight: 400;
                                    font-size: 11px;
                                    line-height: normal;
                                    font-family: $primaryFont;
                                }
                            }

                            .gl_img {
                                img {
                                    width: 100px;
                                    height: 125px;
                                }
                            }
                        }
                    }

                    .gl_bought {
                        display: none;
                    }

                    .gl_action {
                        display: flex;
                        justify-content: space-between;
                        align-items: baseline;
                        margin-top: 10px;
 
                        .gl_unmarked,
                        .gl_marked {
                            cursor: pointer;
                            color: $black;
                            font-style: normal;
                            font-weight: 500;
                            font-size: 12px;
                            line-height: normal;
                            font-family: $primaryFont;
                            letter-spacing: 0.6px;
                            text-align: center;
                            text-decoration: underline;
                            text-transform: uppercase;
                        }

                        .gl_unmarked {
                            display: none;
                        }

                        .addToBasket {
                            display: block;
                            background-image: url('../svg/spritesheet.svg');
                            content: '';
                            @include bgIconCoord(9,8,8);
                            transform: translateY(3px);
                            cursor: pointer;
                            width: 1rem;
                            height: 1rem;
                        }
                    }

                    &.bought {
                        a {
                            opacity: 0.5;
                        }

                        .gl_bought {
                            display: block;
                        }

                        .gl_delete {
                            display: none;
                        }

                        .gl_action {
                            .gl_unmarked {
                                display: block;
                            }

                            .gl_marked,
                            .addToBasket {
                                display: none;
                            }
                        }

                        .gl_img {
                            position: relative;
    
                            .gl_bought {
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                width: 100%;
                                font-size: 9px;
                                text-align: center;
                            }
    
                            img {
                                opacity: 0.5;
                            }
                        }
                    }
                }

                .to_tunnel {
                    display: block;
                    position: absolute;
                    bottom: 0px;
                    left: 50%;
                    transform: translateX(-50%);
                    margin: 0 auto 22px;
                    border-radius: 50px;
                    background-color: $black;
                    padding: 18px 0;
                    width: 100%;
                    max-width: 350px;
                    color: $white;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 13px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.04rem;
                    text-align: center;
                    text-transform: uppercase;
                }
            }
        }

        .to_tunnel,
        .share_list {
            display: block;
            border-radius: 50px;
            background-color: $black;
            padding: 18px 0;
            width: 100%;
            max-width: 350px;
            color: $white;
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: normal;
            font-family: $primaryFont;
            letter-spacing: 0.04rem;
            text-align: center;
            text-transform: uppercase;
        }

        .share_list {
            margin-left: 30px;
        }
    }

    #gl_products_div:not(:has(.gl_nb_product)) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        .desc_pannel {
            width: 100%;
        }
    }

    #gl_donors {
        margin-bottom: 50px;

        &:has(#gl_deactivate) {
            margin-bottom: 10px;
        }

        .donors_title {
            color: $black;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            font-family: $primaryFont;
            letter-spacing: 0.7px;
            text-transform: uppercase;
        }

        .no_donors {
            margin-top: 10px;
        }

        .donors_list {
            .donor {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                border-top: 1px solid $lightgrey;
                padding: 30px 0;

                &:first-of-type {
                    border-top: none;
                }

                .gl_name,
                .gl_amount,
                .gl_message {
                    width: 25%;
                    color: $black;
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    font-family: $primaryFont;
                }

                .gl_message {
                    width: 600px;
                }

                .gl_thank {
                    cursor: pointer;
                    width: 25%;
                    color: $black;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-align: right;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    text-transform: uppercase;
                }
            }

            .w-info-form {
                padding-bottom: 2rem;
            }
        }
    }

    #gl_deactivate {
        cursor: pointer;
        color: $black;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        font-family: $primaryFont;
        letter-spacing: 0.6px;
        text-align: center;
        text-decoration: underline;
        text-transform: uppercase;
    }

    #gl_lightbox_close {
        padding: 30px 6% 5px;
        height: 15%;

        .header_lightbox {
            .txt_alert {
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 18px;
                font-family: $primaryFont;
            }
        }

        .btn_lightbox {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            margin-top: 20px;

            div {
                color: $black;
                font-style: normal;
                font-weight: 500;
                font-size: 12px;
                line-height: normal;
                font-family: $primaryFont;
                letter-spacing: 0.6px;
                text-align: center;
                text-decoration: underline;
                text-transform: uppercase;
            }
        }
    }

    .headerContainer.sticky {
        .list_regroup_content {
            .lightbox {
                top: 80px;
                height: calc(100vh - 80px);
                height: calc(100dvh - 80px);
            }
        }
    }

    .shad.actif {
        z-index: 50;
    }
}

body.gift_list_manage {
    .gift_list_wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: center;
        height: 100%;

        .gift_list {
            margin: 0 20px 30px 0;
            border: 1px solid $grey;
            padding: 20px;
            width: 100%;
            max-width: 310px;

            .gl_picture,
            .gl_no_picture_wrapper {
                display: flex;
                justify-content: center;

                img,
                .gl_no_picture {
                    border-radius: 120px;
                    width: 80px;
                    height: 80px;
                }

                .gl_no_picture {
                    background-image: url('../img/profile_image.png');
                    background-size: contain;
                }
            }

            .gl_title,
            .gl_type,
            .gl_date,
            .gl_participants,
            .gl_amount {
                color: $black;
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                font-family: $primaryFont;
                letter-spacing: 0.7px;
            }

            .gl_title {
                margin-top: 15px;
                text-transform: uppercase;
            }

            .gl_update {
                margin-top: 10px;

                span {
                    color: $black;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 12px;
                    line-height: normal;
                    font-family: $primaryFont;
                    letter-spacing: 0.6px;
                    text-align: center;
                    text-decoration: underline;
                    text-underline-offset: 5px;
                    text-transform: uppercase;
                }
            }

            .gift_list_info {
                display: flex;
                flex-direction: column;
            }
        }
    }

    .btn_list_create {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: $black;
        width: 100%;
        max-width: 350px;
        height: 50px;
        color: $white;
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: normal;
        font-family: $primaryFont;
        letter-spacing: 0.04rem;
        text-align: center;
        text-transform: uppercase;
    }
}